// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment: { [key: string]: any } & {
  production: boolean;
  maintainerAircraft_role: string;
  azure_client_id: string;
  msal: {
    auth: {
      clientId: string;
      authority: string;
      redirectUri: string;
    };
    cache: {
      cacheLocation: string;
      storeAuthStateInCookie: boolean;
    };
  };
  guard: {
    interactionType: string;
    authRequest: {
      scopes: string[];
      prompt: string;
    };
  };
  interceptor: {
    interactionType: string;
    protectedResourceMap: [string, string[]][];
  };
} = {
  production: false,
  maintainerAircraft_role: "mantto_support_rparefdocactual_dev",
  azure_client_id: "51850476-b7cf-4eba-b93a-d03dfe8638c0",
  msal: {
    auth: {
      clientId: "51850476-b7cf-4eba-b93a-d03dfe8638c0",
      authority:
        "https://login.microsoftonline.com/99d911b9-6dc3-401c-9398-08fc6b377b74",
      redirectUri: "https://itinerario-mantto.dev.appslatam.com/mantenedor",
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  },
  guard: {
    interactionType: "redirect",
    authRequest: {
      scopes: ["user.read", "directory.read.all"],
      prompt: "select_account",
    },
  },
  interceptor: {
    interactionType: "redirect",
    protectedResourceMap: [
      [
        "https://graph.microsoft.com/v1.0/me",
        ["user.read", "directory.read.all"],
      ],
    ],
  },
};

export const bff = {
  protocol: "https://",
  host: "itinerario-mantto.dev.appslatam.com",
  port: "",
  editAircraft: "/itin-compara-mant/maintainer-aircraft-bff/edit-aircraft",
  saveAircraft: "/itin-compara-mant/maintainer-aircraft-bff/save-aircraft",
  getAircraft: "/itin-compara-mant/maintainer-aircraft-bff/get-aircraft",
  emailValidation: "/itin-compara-mant/validateEmail/email",
  tokenValidation:
    "/itin-compara-mant/authentication/verifyGoogleSecurityToken",
};

// export const bff = {
//     protocol: 'http://',
//     host: 'localhost',
//     port: ':8085',
//     getExampleBL: '/api/bff/example',
//     getGreeting: '/api/bff/example',
//     emailValidation: '/api/bff/example/email',
//     tokenValidation: '/api/bff/authentication/verifyGoogleSecurityToken'
// };

export const google = {
    clientId: '541332801297-1ml9v12tdefdf001vp430bf10vo1ko1i.apps.googleusercontent.com'
};
/* clientId: '{APP_CLIENTID}'
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
