import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  public static setLanguageCode(code: string) {
    localStorage.setItem("language_iso", code);
  }

  public static getLanguageCode() {
    let code = localStorage.getItem("language_iso");

    if (code == null) {
      code = "es";
    }

    return code;
  }

  public static getServerUrl() {
    return localStorage.getItem("server_url");
  }

  public static setServerUrl(url: string) {
    localStorage.setItem("server_url", url);
  }

  public static setVersion(version: string) {
    sessionStorage.setItem("version", version);
  }

  public static getVersion() {
    return sessionStorage.getItem("version");
  }

  public static setApps(appsArray: any) {
    sessionStorage.setItem("apps", JSON.stringify(appsArray));
  }

  public static getApps() {
    let appsJson = sessionStorage.getItem("apps");

    if (appsJson == null) {
      return [];
    }

    return JSON.parse(appsJson);
  }

  public static storeAzureClientId(clientId: string) {
    sessionStorage.setItem("azure_client_id", clientId);
  }

  public static storeAzureTenantId(tenantId: string) {
    sessionStorage.setItem("azure_tenant_id", tenantId);
  }

  public static setAzureUseRedirect(useRedirect: boolean) {
    localStorage.setItem("azure_use_redirect", useRedirect ? "1" : "0");
  }

  public static getAzureUseRedirect(): boolean {
    let ret = localStorage.getItem("azure_use_redirect");
    if (ret === "1") {
      return true;
    }

    return false;
  }

  public static storeAuzreAuthority(authority: string) {
    sessionStorage.setItem("azure_authority", authority);
  }

  public static storeAzureRedirectUri(redirectUri: string) {
    sessionStorage.setItem("azure_redirect_uri", redirectUri);
  }

  public static getAzureClientId(): string {
    let r = sessionStorage.getItem("azure_client_id");

    return r == null ? "" : r;
  }

  public static getAzureTenantId(): string {
    let r = sessionStorage.getItem("azure_tenant_id");

    return r == null ? "" : r;
  }

  public static getAzureAuthority(): string {
    let r = sessionStorage.getItem("azure_authority");

    return r == null ? "" : r;
  }

  public static getAzureRedirectUri(): string {
    let r = sessionStorage.getItem("azure_redirect_uri");

    return r == null ? "" : r;
  }

  public static getIsConfigured(): boolean {
    return localStorage.getItem("server_url") ? true : false;
  }

  public static setStorage(json: any) {
    let storage = json.storage;

    if (storage == null) {
      return;
    }
    for (let key in storage) {
      localStorage.setItem(key, storage[key]);
    }
  }

  public static getItemStorage(key: string): any {
    return localStorage.getItem(key);
  }

  public static b2cFlow(): boolean {
    let ret = localStorage.getItem("B2C");

    if (ret === "1") {
      return true;
    }

    return false;
  }

  public static setLoginWithRedirect(useRedirect: boolean) {
    localStorage.setItem("login_with_redirect", useRedirect ? "1" : "0");
  }

  public static isLoggedWithRedirect(): boolean {
    let ret = localStorage.getItem("login_with_redirect");

    if (ret === "1") {
      return true;
    }

    return false;
  }

  public static clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }

  public static setConfigAzure(config: string) {
    localStorage.setItem("config_azure", config);
  }

  public static getConfigAzure() {
    let r = localStorage.getItem("config_azure");
    console.log("r::", r);
    return r == null ? "/environments/environment.ts" : r;
  }

  public static setLoadingLogin(loading: boolean) {
    localStorage.setItem("loading_login", loading ? "1" : "0");
  }

  public static isLoadingLogin(): boolean {
    let ret = localStorage.getItem("loading_login");

    if (ret === "1") {
      return true;
    }

    return false;
  }
}
