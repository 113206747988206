import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { SessionService } from 'src/app/services/session.service';

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  public user = { name: "" };

  constructor(
    private authService: SessionService,
    private router: Router,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.actualizarUsuario();
  }

  actualizarUsuario() {
    if (JSON.parse(this.cookieService.get("acme-user-info")) !== null) {
      this.user = JSON.parse(this.cookieService.get("acme-user-info"));
    }
  }

  logoff() {
    this.cookieService.delete("acme-user-token");
    this.cookieService.delete("acme-user-info");
    this.authService.logout();
    // this.authService.signOut();
    this.router.navigate([""]);
  }
}
