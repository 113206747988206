import { Injectable } from "@angular/core";

import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

import { CookieService } from "ngx-cookie-service";
import { SessionService } from "../session.service";

@Injectable()
export class AuthGuardService {
  currentUser: any;
  constructor(protected router: Router, private cookieService: CookieService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (route.url[0].path.indexOf("/secure/") >= 0) {
      if (SessionService.getAuthorizationToken() == "null") {
        this.router.navigate(["/"]);
        return false;
      }
    }

    if (SessionService.getAuthorizationToken()) {
      this.currentUser = JSON.parse(this.cookieService.get("acme-user-info"));
      return true;
    } else {
      this.router.navigate(["/no-access"], { queryParams: { expired: true } });
      return false;
    }
  }

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canActivate(route, state);
  }
}
