<header id="header">
    <nav class="navbar navbar-expand-lg navbar-blue">
        <a class="navbar-brand">
            <img src="assets/images/logo-latam-2.png" alt="Logo LATAM" class="logo-latam-header">
        </a>
        <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <button mat-icon-button [matMenuTriggerFor]="menu" class="text-white"
                    aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item>
                    <mat-icon>engineering</mat-icon>
                    <span>Mantenedor</span>
                </button>
                <button mat-menu-item>
                    <mat-icon>compare_arrows</mat-icon>
                    <span>Comparador</span>
                </button>

            </mat-menu>
        </div>
        <div class="user-logout">
            <label id="nombreUsuario" class="text-white mr-3"
                   style="margin-top: 0.5%;float: left;">{{user.name}}</label>
            <i class="material-icons text-white icon-header" style="cursor:pointer; display: flex;" (click)="logoff()">
                logout</i>
        </div>
    </nav>
</header>
