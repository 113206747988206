import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MsalGuardAuthRequest,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import {
  AccountInfo,
  AuthenticationResult,
  PopupRequest,
  SilentRequest,
} from "@azure/msal-browser";
import { TranslateService } from "@ngx-translate/core";
import { CookieService } from "ngx-cookie-service";
import { AppRoutingModule } from "src/app/app-routing.module";
import { environment } from "src/environments/environment";
import { ROLES_CONFIG } from "./roles/roles.config";
import { IRoleConfig, IRolesMenu } from "./roles/roles.interface";
import { StorageService } from "./storage.service";
import { UiService } from "./ui.service";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private static loggedInUsername: string = "";
  private static SESSION_ROLES_CONFIG = ROLES_CONFIG;
  private timer: any;
  currentUser = { name: "", email: "", image: "", components: [], role: "" };
  private static NO_ACCESS_ROUTE = "no-access";

  constructor(
    private router: Router,
    private authService: MsalService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private ui: UiService,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {}

  public static getLoggedInUsername() {
    let id = sessionStorage.getItem("id");

    if (id === null) {
      return "";
    }

    return atob(id);
  }

  public logout() {
    this.clearLoginInfo();
    if (StorageService.isLoggedWithRedirect()) {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: StorageService.getAzureRedirectUri(),
      });
    } else {
      this.router.navigate(["/login"]);
    }
  }

  static getRoles(): any | null {
    let roles = sessionStorage.getItem("roles");

    if (roles == null || roles == undefined || roles == "undefined") {
      return null;
    }

    return JSON.parse(roles);
  }

  static storeError(error: string) {
    sessionStorage.setItem("last_http_error", error);
  }

  static getLastError(): string | null {
    return sessionStorage.getItem("last_http_error");
  }

  public static isLoggedIn(): boolean {
    let token = sessionStorage.getItem("token");

    if (token == null || token == undefined || token == "undefined") {
      return false;
    }

    return true;
  }

  static getAuthorizationToken(): string {
    let token = sessionStorage.getItem("token");
    if (token == null || token == undefined || token == "undefined") {
      return "null";
    }

    return token;
  }

  public static setLoginInfo(token: string, roles: any) {
    sessionStorage.setItem("token", token);
    sessionStorage.setItem("roles", JSON.stringify(roles));
    sessionStorage.setItem("id", SessionService.loggedInUsername);
  }

  private clearLoginInfo() {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("roles");
    sessionStorage.removeItem("id");
  }

  public static getRolesConfig(): IRoleConfig[] {
    const userRoles: any[] = SessionService.getRoles();

    SessionService.SESSION_ROLES_CONFIG.forEach((menuConfig: IRoleConfig) => {
      menuConfig.roles.forEach((roleConfig: IRolesMenu) => {
        const storedRole = environment[roleConfig.role];
        if (storedRole) {
          roleConfig.storageRole = storedRole;
          roleConfig.hasRole = userRoles.includes(storedRole);
        } else {
          roleConfig.hasRole = false;
        }
      });
    });
    return SessionService.SESSION_ROLES_CONFIG;
  }

  public static redirectByRole(router: Router) {
    const rolesConfig = SessionService.getRolesConfig();
    for (const menuConfig of rolesConfig) {
      for (const roleConfig of menuConfig.roles) {
        if (roleConfig.hasRole) {
          router.navigate([roleConfig.redirect]);
          return;
        }
      }
    }
    const queryParams = { hasRoles: "false" };
    router.navigate([this.NO_ACCESS_ROUTE], { queryParams });
  }

  public static canUserAccessRoute(route: string): boolean {
    const myRoles = SessionService.getRoles();
    let ret = false;

    if (myRoles != null) {
      const routesByRole: any = AppRoutingModule.getRoutesByRole();
      console.log("routesByRole", routesByRole);
      const me = this;

      let data = JSON.stringify(routesByRole);

      data = JSON.parse(data);

      myRoles.forEach((role: string | number) => {
        if (routesByRole[role] !== undefined) {
          routesByRole[role].forEach((allowedRoute: any) => {
            if (route == allowedRoute) {
              ret = true;
            }
          });
        }
      });
    }

    return ret;
  }

  public static getAvailableRoutes(): string[] {
    let routesByRole = AppRoutingModule.getRoutesByRole();

    let myAvailableRoutes: string[];

    const myRoles = SessionService.getRoles();

    let myRoutes: string[] = [];

    let ret = false;

    if (myRoles != null) {
      const routesByRole: any = AppRoutingModule.getRoutesByRole();

      const me = this;

      let data = JSON.stringify(routesByRole);

      data = JSON.parse(data);

      myRoles.forEach((role: string | number) => {
        if (routesByRole[role] !== undefined) {
          routesByRole[role].forEach((allowedRoute: any) => {
            myRoutes.push(allowedRoute);
          });
        }
      });
    }

    return myRoutes;
  }

  public async loginWithAzureB2C(): Promise<any> {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect();
    } else {
      return new Promise<void>((resolve, reject) => {
        this.authService.loginPopup().subscribe({
          next: async (result) => {
            await this.handleLoginWithAzureADResponse(result);

            resolve();
          },
          error: (error) => {
            console.log(error);

            reject(error);
          },
        });
      });
    }
  }

  public async loginWithAzureADRedirect(): Promise<any> {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({
        scopes: ["user.read", "directory.read.all"],
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        this.authService.loginPopup().subscribe({
          next: async (result) => {
            await this.handleLoginWithAzureADResponse(result);

            resolve();
          },
          error: (error) => {
            console.log(error);

            reject(error);
          },
        });
      });
    }
  }

  public async loginWithAzureAD(): Promise<any> {
    if (this.msalGuardConfig.authRequest) {
      return new Promise<void>((resolve, reject) => {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe({
            next: async (result: any) => {
              await this.handleLoginWithAzureADResponse(result);
              resolve();
            },
            error: (error: any) => {
              console.log("Error en loginWithAzureAD");
              console.log(error);
              reject(error);
            },
          });
      });
    } else {
      return new Promise<void>((resolve, reject) => {
        this.authService.loginPopup().subscribe({
          next: async (result) => {
            await this.handleLoginWithAzureADResponse(result);
            resolve();
          },
          error: (error) => {
            console.log(error);
            reject(error);
          },
        });
      });
    }
  }

  private encrypt(text: string): string {
    return btoa(text);
  }

  public async handleloginWithAzureB2CResponse(response: any) {
    const tokenClaims: any = JSON.parse(JSON.stringify(response.idTokenClaims));
    let id = this.encrypt(response.account?.username);
    if (id == null) {
      id = "";
    }
    SessionService.loggedInUsername = id;
    SessionService.setLoginInfo(response.idToken, ["demo.user"]);
  }

  public async handleLoginWithAzureADResponse(response: any) {
    const tokenClaims: any = JSON.parse(JSON.stringify(response.idTokenClaims));
    let id = this.encrypt(response.account?.username);
    if (id == null) {
      id = "";
    }
    SessionService.loggedInUsername = id;
    SessionService.setLoginInfo(response.idToken, tokenClaims.roles);
    this.currentUser.email = response.account?.username;
    let token = response.idToken;
    if (response.account?.name) {
      this.currentUser.name = response.account?.name
        .replace(",", "")
        .trim()
        .replace(/\s+/g, " ");
    } else {
      this.currentUser.name = "";
    }

    this.cookieService.set(
      "acme-user-info",
      JSON.stringify(this.currentUser),
      1,
      "/",
      "",
      true,
      "Strict"
    );
    this.cookieService.set(
      "acme-user-token",
      token,
      1,
      "/",
      "",
      true,
      "Strict"
    );
  }

  public startSessionTimer(): void {
    const tokenExpiration = this.msalAccount.idTokenClaims?.exp ?? 0;

    const expirationTime = (tokenExpiration - Date.now() / 1000 - 120) * 1000;

    this.timer = setTimeout(async () => {
      let res = await this.ui.showQuestionDialog(
        this.translate.instant("TIMEOUT_LOGOUT_TITTLE"),
        this.translate.instant("TIMEOUT_LOGOUT_MESSAGE"),
        "full-screen-modal",
        120
      );

      if (res == "yes") {
        this.refreshToken();
      } else {
        this.logout();
      }
    }, expirationTime);
  }

  private get msalAccount(): AccountInfo {
    return this.authService.instance.getAllAccounts()[0];
  }

  private refreshToken(): void {
    console.log("==> Session Manager: Refreshing token...");

    const { scopes } = this.msalGuardConfig.authRequest as MsalGuardAuthRequest;

    const request: SilentRequest = {
      scopes: scopes as string[],
      account: this.msalAccount,
      forceRefresh: true,
    };

    this.authService.instance
      .acquireTokenSilent(request)
      .then(async (res: AuthenticationResult) => {
        console.log("==> Session Manager: Token refrescado exitosamente");

        const tokenClaims: any = JSON.parse(JSON.stringify(res.idTokenClaims));

        SessionService.setLoginInfo(res.idToken, tokenClaims.roles);

        this.resetSessionTimer();
      })
      .catch((error) => {
        console.error(
          "==> Session Manager: Error al refrescar el token:",
          error
        );
      });
  }

  resetSessionTimer(): void {
    console.log("==> Session Manager: Resetting Session timer...");

    clearTimeout(this.timer);

    this.startSessionTimer();
  }
}
