import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { MaterialModule } from "./modules/material.module";

/* Components */
import { AppComponent } from "./app.component";
import { InfoQuestionDialogComponent } from "./components/info-question-dialog/info-question-dialog.component";
import { LoginComponent } from "./components/login/login.component";
import { MaintainerAircraftComponent } from "./components/mantainer/maintainer-aircraft/maintainer-aircraft.component";
import { MantainerComponent } from "./components/mantainer/mantainer.component";
import { NavbarComponent } from "./components/navbar/navbar.component";
import { FooterComponent } from "./components/section/footer/footer.component";
import { HeaderComponent } from "./components/section/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";

/* Services */
import { AuthInterceptorService } from "./services/interceptor/auth-interceptor.service";
import { HttpErrorInterceptorService } from "./services/interceptor/error-interceptor.service";
import { InterceptorService } from "./services/interceptor/interceptor.service";
import { AuthGuardService } from "./services/security/auth-guard.service";

/* Angular Material */
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSortModule } from "@angular/material/sort";

/* MSAL */
import { MsalGuard, MsalInterceptor, MsalService } from "@azure/msal-angular";
import { MsalConfigDynamicModule } from "./msal-config-dynamic.module";

/* Translation */
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { CookieService } from "ngx-cookie-service";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    MantainerComponent,
    MaintainerAircraftComponent,
    InfoQuestionDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatSortModule,
    MatDialogModule,
    MatRippleModule,
    MatProgressSpinnerModule,
    MsalConfigDynamicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    CookieService,
    AuthGuardService,
    MsalGuard,
    MsalService,
    {
      provide: "Window",
      useValue: window,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    provideAnimationsAsync(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
