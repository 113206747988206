import { AfterViewInit, Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from "@azure/msal-angular";
import { SessionService } from "src/app/services/session.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements AfterViewInit, OnInit {
  loggedIn: boolean = false;

  errorLogin: boolean = false;

  showLogin = false;

  loading: boolean = false;

  showBtn: boolean = false;

  constructor(
    private msalService: MsalService,
    private sessionService: SessionService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router,
  ) {
    this.loggedIn = this.isLoggedIn();
    this.loading = this.isLoading();
  }

  ngOnInit() {
    let subscription = this.msalService
      .handleRedirectObservable()
      .subscribe((response) => {
        if (response?.account) {
          subscription.unsubscribe();
          this.sessionService.handleLoginWithAzureADResponse(response);
          StorageService.setLoadingLogin(false);
          SessionService.redirectByRole(this.router);
        }
      });
  }

  onToggleChange() {
    StorageService.clearStorage();
    StorageService.setConfigAzure("/environments/environment.ts");
    location.reload();
  }

  async login() {
    StorageService.setLoadingLogin(true);
    if (StorageService.getAzureUseRedirect() === true) {
      StorageService.setLoginWithRedirect(true);
      await this.sessionService.loginWithAzureADRedirect();
      this.sessionService.startSessionTimer();
      SessionService.redirectByRole(this.router);
    } else {
      StorageService.setLoginWithRedirect(false);
      await this.sessionService.loginWithAzureAD();
      this.sessionService.startSessionTimer();
      StorageService.setLoadingLogin(false);
      SessionService.redirectByRole(this.router);
    }
  }

  logout() {
    this.sessionService.logout();

    this.loggedIn = false;
  }

  private isLoading() {
    return StorageService.isLoadingLogin();
  }

  private isLoggedIn() {
    return SessionService.isLoggedIn();
  }

  refreshLogin() {
    location.reload();
  }

  setLoginDisplay() {
    this.showLogin = this.msalService.instance.getAllAccounts().length > 0;
  }

  ngAfterViewInit(): void {
    if (SessionService.isLoggedIn() == true) {
      return;
    }
  }
}
