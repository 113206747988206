import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { MantainerComponent } from './components/mantainer/mantainer.component';
import { AuthGuardService } from './services/security/auth-guard.service';

const appRoutes: Routes = [
  { path: "", component: LoginComponent },
  {
    path: "mantenedor",
    component: MantainerComponent,
    canActivate: [AuthGuardService],
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  private static routesByRole: any = {
    kioskossss_Reader_poc4: [
      "main",
      "server-paging",
      "client-paging",
      "profile",
      "settings",
      "apps",
    ],
    "demo.user": [
      "main",
      "server-paging",
      "client-paging",
      "profile",
      "settings",
      "apps",
    ],
    admin: ["main", "admin", "server-paging", "settings"],
    "demo.admin": [
      "main",
      "server-paging",
      "client-paging",
      "profile",
      "settings",
      "apps",
    ],
  };

  public static getRoutesByRole() {
    return AppRoutingModule.routesByRole;
  }
}

