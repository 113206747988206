import { Component, HostListener } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    // Prepare the translate module to use spanish as default

    this.translate.setDefaultLang("es");

    // Use previously stored language code

    this.translate.use("es");
  }
  @HostListener("window:beforeunload", ["$event"])
  beforeunloadHandler(event: any) {
    // this.cookieService.delete("acme-user-token");
    // this.cookieService.delete("acme-user-info");
  }
}
