import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import swal from 'sweetalert2';
import { Aircraft } from '../../../models/aircraft';
import { AircraftTable } from '../../../models/aircraft-table';
import { MaintainerService } from '../../../services/maintainer/maintainer.service';


@Component({
    selector: 'app-maintainer-aircraft',
    templateUrl: './maintainer-aircraft.component.html',
    styleUrls: ['./maintainer-aircraft.component.scss']
})
export class MaintainerAircraftComponent implements OnInit {

    displayedColumns = ['id', 'aircraftMxi', 'aircraftOper', 'aircraftModif', 'aircarftActive', 'action'];
    dataSource: any;
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator | undefined;
    @ViewChild(MatSort, {static: false}) sort: MatSort | undefined;
    @ViewChild(MatTable, {static: false}) tableAircraft: MatTable<Aircraft> | undefined;
    @ViewChild('addAircraft', {static: false}) addAircraft: NgbModal | undefined;
    @ViewChild('editAircraft', {static: false}) editAircraft: NgbModal | undefined;
    id: number | undefined;
    aircraftOper = '';
    aircraftMxi = '';
    aircraftModif = '';
    active: boolean | undefined;

    bodyEdit = {};
    aircraft: UntypedFormGroup;
    disabled = false;
    loading: boolean;
    btnSpinner: boolean | undefined;

    constructor(private ms: MaintainerService,
                private modalService: NgbModal,
                private formBuilder: UntypedFormBuilder) {
        this.loading = true;
        this.aircraft = this.formBuilder.group({
            id: [''],
            aircraftOper: ['', [Validators.required, Validators.pattern('([A-Z0-9]{6})|([A-Z0-9]{5})')]],
            aircraftMxi: ['', [Validators.required, Validators.pattern('([A-Z0-9]{2}\\-[A-Z0-9]{3})|([A-Z0-9]{6})|([A-Z0-9]{5})')]],
            aircraftModif: [''],
            active: [''],
        });
        this.id = undefined;
    }

    openEdit(id: number, oper: string, mxi: string, modif: string, active: boolean) {
        this.id = id;
        this.aircraftOper = oper;
        this.aircraftMxi = mxi;
        this.aircraftModif = modif;
        this.active = active;

        this.bodyEdit = {
            id: this.id,
            aircraftOper: this.aircraftOper,
            aircraftMxi: this.aircraftMxi,
            active: this.active
        };
        this.modalService.open(this.editAircraft, {ariaLabelledBy: 'modal-basic-title'}).result.then(() => {
        }, () => {
        });
    }

    ngOnInit() {
        this.renderDataTable();
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    renderDataTable() {
        this.ms.getAircraft()
            .subscribe(
              x => {
                if(x!=null){
                  this.dataSource = new MatTableDataSource();
                  this.dataSource.data = x.data;
                  this.dataSource.sort = this.sort;
                  this.dataSource.paginator = this.paginator;
                }
                this.loading = false;
                },
                error => {
                    console.log('Error al cargar los datos!' + error);
                });
    }

    startAdd(event: any) {
        event.preventDefault();
        this.aircraft?.setValue({
            id: null,
            aircraftOper: null,
            aircraftMxi: null,
            aircraftModif: null,
            active: null
        });

        this.modalService.open(this.addAircraft, {ariaLabelledBy: 'modal-basic-title'}).result.then(() => {

        }, () => {
        });
    }

    onSubmit(event: any) {

        event.preventDefault();
        this.disabled = true;
        this.btnSpinner = true;
      this.ms.addAircraft(this.aircraft?.value).subscribe(res => {
          if(res){
            swal.fire(res.title, res.message, res.icon);
          }
            this.disabled = !this.disabled;
            this.btnSpinner = false;
            this.renderDataTable();
            this.modalService.dismissAll();
        });
    }

    onSubmitEdit(event: any) {

        event.preventDefault();

        this.aircraft?.patchValue({
            id: this.id
        });
        if (this.aircraft?.controls[AircraftTable.ID].value === null || this.aircraft?.controls[AircraftTable.ID].value === '') {
            this.aircraft?.patchValue({
                id: this.id?.toString().toUpperCase()
            });
        }
        if (this.aircraft?.controls[AircraftTable.AIRCRAFTOPER].value === null
            || this.aircraft?.controls[AircraftTable.AIRCRAFTOPER].value === '') {
            this.aircraft.patchValue({
                aircraftOper: this.aircraftOper.toUpperCase()
            });
        }
        if (this.aircraft?.controls[AircraftTable.AIRCRAFTMXI].value === null
            || this.aircraft?.controls[AircraftTable.AIRCRAFTMXI].value === '') {
            this.aircraft.patchValue({
                aircraftMxi: this.aircraftMxi.toUpperCase()
            });
        }
        if (this.aircraft?.controls[AircraftTable.AIRCRAFTMODIF].value === null
            || this.aircraft?.controls[AircraftTable.AIRCRAFTMODIF].value === '') {
            this.aircraft.patchValue({
                aircraftModif: this.aircraftModif.toUpperCase()
            });
        }
        if (this.aircraft?.controls[AircraftTable.AIRCRAFTACTIVE].value === null
            || this.aircraft?.controls[AircraftTable.AIRCRAFTACTIVE].value === '') {
            this.aircraft.patchValue({
                active: this.active
            });
        }
        this.disabled = true;
        this.btnSpinner = true;
        this.ms.editAicraft(this.aircraft?.value).subscribe(res => {
            swal.fire(
                res.title,
                res.message,
                res.icon
            );
            this.renderDataTable();
            this.disabled = false;
            this.btnSpinner = false;
            this.modalService.dismissAll();
        });

    }

}

