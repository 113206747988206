<!-- <div style="padding:20px;width:max-content;height:max-content">
	<div style="width:100%">
		<h1>{{title}}</h1>
	</div>
	<p></p>
	<span style="font-size:medium">{{subtitle}} </span>
	<p></p>
	<span style="font-size:medium">{{description}}</span>

	<div style="text-align: right; width:100%;text-align:center;margin-top:40px;margin-left:auto;margin-right:auto">		
		<button style="margin-left:10px" color="accent" *ngIf="yesVisible==true" mat-raised-button [mat-dialog-close]="'yes'"
			cdkFocusInitial>BUTTON_YES</button>
			<button style="margin-left:10px" *ngIf="okVisible==true" mat-raised-button [mat-dialog-close]="'ok'"
			cdkFocusInitial>BUTTON_OK</button>
			<button style="margin-left:10px" *ngIf="noVisible==true" mat-raised-button [mat-dialog-close]="'no'"
			cdkFocusInitial>BUTTON_NO</button>
			<button style="margin-left:10px" *ngIf="cancelVisible==true" mat-raised-button
			[mat-dialog-close]="'cancel'">BUTTON_CANCEL</button>
			<button style="margin-left:10px" *ngIf="desistVisible==true" mat-raised-button
			[mat-dialog-close]="'cancel'">BUTTON_DESIST</button>
	</div>
</div> -->

<div style="padding:20px;width:max-content;height:max-content">
	<div style="width:100%">
		<h1>{{title}}</h1>
	</div>
	<p></p>
	<span style="font-size:medium">{{subtitle}} </span>
	<p></p>
	<span style="font-size:medium">{{description}}</span>

	<div style="text-align: right; width:100%;text-align:center;margin-top:40px;margin-left:auto;margin-right:auto">		
		<button style="margin-left:10px" color="accent" *ngIf="yesVisible==true" mat-raised-button [mat-dialog-close]="'yes'"
			cdkFocusInitial>BUTTON_YES</button>
			<button style="margin-left:10px" *ngIf="okVisible==true" mat-raised-button [mat-dialog-close]="'ok'"
			cdkFocusInitial>BUTTON_OK</button>
			<button style="margin-left:10px" *ngIf="noVisible==true" mat-raised-button [mat-dialog-close]="'no'"
			cdkFocusInitial>BUTTON_NO</button>
			<button style="margin-left:10px" *ngIf="cancelVisible==true" mat-raised-button
			[mat-dialog-close]="'cancel'">BUTTON_CANCEL</button>
			<button style="margin-left:10px" *ngIf="desistVisible==true" mat-raised-button
			[mat-dialog-close]="'cancel'">BUTTON_DESIST</button>
	</div>
</div>