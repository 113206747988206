export interface IRoleConfig {
  menu: ENavMenu;
  roles: IRolesMenu[];
}

export interface IRolesMenu {
  role: string;
  storageRole?: string;
  hasRole?: boolean;
  basePath: string;
  redirect: string;
  menuName: string;
}

export enum ENavMenu {
  MAINTAINER = "Maintainer",
  COMPARATOR = "Comparator",
}
