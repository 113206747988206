import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
    providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
    constructor(private cookieService: CookieService) {
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const token: string = this.cookieService.get('acme-user-token');
        let request = req;
        if (token != null) {
            request = req.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token
                }
            });

        }
        return next.handle(request);
    }
}
