<div class="container" style="padding-top:40px">
	<mat-card class="app_card" style="width:320px;height: min-content;">

		<img alt="Logo LATAM" src='/assets/pics/logo-latam-airlines-w.png' width="120px">

		<p style="margin-top:40px;text-align: center;">{{'LABEL_WELCOME_LOGIN_1'|translate}}</p>

		<p style="margin-top:20px;text-align: center; font-weight: 900;">{{'LABEL_WELCOME_LOGIN_2'|translate}}</p>

		<button style="margin-top:10px;" type="button" class="app_button" mat-raised-button
			(click)="login()">{{'BUTTON_LOGIN'|translate}}</button>

	</mat-card>
</div>


<div class="app_footer">
	<app-footer></app-footer>
</div>
