import {Component, OnInit} from '@angular/core';


@Component({
  selector: 'app-mantainer',
  templateUrl: './mantainer.component.html',
  styleUrls: ['./mantainer.component.css']
})
export class MantainerComponent implements OnInit {


  constructor() {


  }

  ngOnInit() {
  }

}
