import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  private settings: any;

  constructor() {
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.settings = environment;
      StorageService.setStorage(environment);
      resolve(true);
    });
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split(".");
    }

    let result = key.reduce(
      (account: any, current: string) => account && account[current],
      this.settings
    );

    return result;
  }
}
