import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { bff } from '../../../environments/environment';
import { AircraftList } from '../../models/aircraft-list';
import { Response } from '../../models/response';

@Injectable({
    providedIn: 'root'
})
export class MaintainerService {
    URL_API: string = bff.protocol + bff.host + bff.port;

    constructor(private httpClient: HttpClient) {
    }

    getAircraft(): Observable<AircraftList> {
        const url = this.URL_API + bff.getAircraft;
        const header = new HttpHeaders(
            {});
        return this.httpClient.get<AircraftList>(url, {headers: header});
    }

    public addAircraft(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'application/json'

            })
        };
        return this.httpClient.post<Response>(this.URL_API + bff.saveAircraft, body, httpOptions);
    }

    public editAicraft(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache'
            })
        };
        return this.httpClient.post<Response>(this.URL_API + bff.editAircraft, body, httpOptions);
    }

}
