import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { EMPTY, Observable } from "rxjs";

import { catchError } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { SessionService } from "../session.service";
import { UiService } from "../ui.service";

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {
  constructor(
    private router: Router,
    private ui: UiService,
    private translate: TranslateService,
    private sessionService: SessionService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return <any>next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          console.error(
            "An error occurred:",
            request.url + " " + err.error.message
          );
        } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          console.error(
            `Backend returned code ${err.status}, url: ${request.url}, body was: ${err.error}` +
              " " +
              err.statusText +
              " " +
              (err.error != null ? err.error.errorStack : "")
          );
        }

        //const message = (err.error instanceof ErrorEvent) ? err.error.message :	`server returned code ${err.status} with body "${err.error}"`;
        const message =
          err.error instanceof ErrorEvent
            ? err.error.message
            : `Call to ${request.url} failed with status code ${err.status}`;

        // TODO: better job of transforming error for user consumption
        SessionService.storeError(message);

        if (err.status === 401) {
          this.sessionService.logout();
        } else if (err.status === 403) {
          this.ui.showErrorDialog(
            this.translate.instant("ERROR_NO_ACCESS"),
            this.translate.instant("LABEL_NO_ACCESS_2")
          );
        } else {
          this.ui.showErrorDialog(
            this.translate.instant("ERROR_TITLE"),
            message
          );

          //this.router.navigateByUrl('/error').then();
        }

        // ...optionally return a default fallback value so app can continue (pick one)
        // which could be a default value (which has to be a HttpResponse here)
        // return Observable.of(new HttpResponse({body: [{name: "Default value..."}]}));
        // or simply an empty observable
        return EMPTY;
      })
    );
  }
}
