<div class="table-container">
    <div class="example-button-row">
        <button mat-stroked-button color="primary" (click)="startAdd($event)">Agregar matricula</button>
    </div>
    <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. CC-BBA" #input>
    </mat-form-field>

    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ID Column -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ID</th>
                <td mat-cell *matCellDef="let row"> {{row.id}} </td>
            </ng-container>

            <!-- Progress Column -->
            <ng-container matColumnDef="aircraftOper">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Matricula Operaciones</th>
                <td mat-cell *matCellDef="let row"> {{row.aircraftOper}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="aircraftMxi">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Matricula Mantenimiento</th>
                <td mat-cell *matCellDef="let row"> {{row.aircraftMxi}} </td>
            </ng-container>

            <!-- Color Column -->
            <ng-container matColumnDef="aircraftModif">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Ultimo Cambio</th>
                <td mat-cell *matCellDef="let row"> {{row.aircraftModif| date:'dd-MM-yyyy HH:mm:ss'}} </td>
            </ng-container>

            <ng-container matColumnDef="aircarftActive">
                <th mat-header-cell *matHeaderCellDef class="centrate"> Estado</th>
                <td mat-cell *matCellDef="let row" class="centrate">
                    <div *ngIf="row.active">
                        <mat-icon class="text-green">airplanemode_active</mat-icon>
                    </div>
                    <div *ngIf="!row.active">
                        <mat-icon class="text-red">airplanemode_inactive</mat-icon>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="centrate"> Acción</th>
                <td mat-cell *matCellDef="let row" class="centrate">
                    <button mat-stroked-button color="primary"
                            (click)="openEdit(row.id,row.aircraftOper,row.aircraftMxi,row.aircraftModif,row.active)">
                        Editar matricula
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>

</div>
<ng-template #addAircraft let-modal>
    <div class="modal-header">
        <h4 class="modal-title color-title" id="modal-basic-title1">Agregar matricula</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form role="form" [formGroup]="aircraft" novalidate>

            <div class="form-group">
                <label>Matricula Operaciones</label>
                <input maxlength="6" type="text" class="form-control" formControlName="aircraftOper"/>
                <div *ngIf="aircraft.get('aircraftOper')?.errors && aircraft.get('aircraftOper')?.dirty">
                    <p *ngIf="aircraft.get('aircraftOper')?.hasError('required')">Campo Requerido</p>
                    <p *ngIf="aircraft.get('aircraftOper')?.hasError('pattern')">Formato ingresado no válido</p>
                </div>
            </div>
            <div class="form-group">
                <label>Matricula Mantenimiento</label>
                <input maxlength="6" type="text" class="form-control" formControlName="aircraftMxi"/>
                <div *ngIf="aircraft.get('aircraftMxi')?.errors && aircraft.get('aircraftMxi')?.dirty">
                    <p *ngIf="aircraft.get('aircraftMxi')?.hasError('required')">Campo Requerido</p>
                    <p *ngIf="aircraft.get('aircraftMxi')?.hasError('pattern')">Formato ingresado no válido</p>
                </div>
            </div>
            <div class="form-group">
                <label>Estado </label>
                <select class="form-control" [(ngModel)]='active' formControlName="active">
                    <option value="true">Activo</option>
                    <option value="false">Inactivo</option>
                </select>
                <div *ngIf="aircraft.get('active')?.errors && aircraft.get('active')?.dirty">
                    <p *ngIf="aircraft.get('active')?.hasError('required')">Campo Requerido</p>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button id="btnAgregar" mat-stroked-button color="primary" [disabled]="aircraft.invalid || btnSpinner" [class.spinner]="btnSpinner"
                (click)="onSubmit($event)">Guardar
        </button>
        <button mat-stroked-button color="primary" (click)="modal.close('Save click')">Cerrar</button>
    </div>
</ng-template>
<ng-template #editAircraft let-modal>
    <div class="modal-header">
        <h4 class="modal-title color-title" id="modal-basic-title">Editar matricula</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form role="form" [formGroup]="aircraft" novalidate>
            <input type="hidden" [value]="id" formControlName="id">

            <div class="form-group">
                <label>Matricula Operaciones</label>
                <input maxlength="6" type="text" [(ngModel)]="aircraftOper" class="form-control"
                       formControlName="aircraftOper"/>
                <div *ngIf="aircraft?.get('aircraftOper')?.errors && aircraft?.get('aircraftOper')?.dirty">
                    <p *ngIf="aircraft?.get('aircraftOper')?.hasError('required')">Campo Requerido</p>
                    <p *ngIf="aircraft?.get('aircraftOper')?.hasError('pattern')">Formato ingresado no válido</p>
                </div>
            </div>
            <div class="form-group">
                <label>Matricula Mantenimiento</label>
                <input maxlength="6" type="text" [(ngModel)]="aircraftMxi" class="form-control"
                       formControlName="aircraftMxi"/>
                <div *ngIf="aircraft?.get('aircraftMxi')?.errors && aircraft?.get('aircraftMxi')?.dirty">
                    <p *ngIf="aircraft?.get('aircraftMxi')?.hasError('required')">Campo Requerido</p>
                    <p *ngIf="aircraft?.get('aircraftMxi')?.hasError('pattern')">Formato ingresado no válido</p>
                </div>
            </div>
            <div class="form-group">
                <label>Estado </label>
                <select class="form-control" [(ngModel)]='active' formControlName="active">
                    <option value="true">Activo</option>
                    <option value="false">Inactivo</option>
                </select>
                <div *ngIf="aircraft?.get('active')?.errors && aircraft?.get('active')?.dirty">
                    <p *ngIf="aircraft?.get('active')?.hasError('required')">Campo Requerido</p>
                </div>
            </div>
        </form>

    </div>
    <div class="modal-footer" style="text-align: center">
        <button id="btnEditar" mat-stroked-button color="primary" [disabled]="aircraft.invalid || btnSpinner" [class.spinner]="btnSpinner"
                (click)="onSubmitEdit($event)">Guardar
        </button>
        <button mat-stroked-button color="primary" (click)="modal.close('Save click')">Cerrar</button>
    </div>
</ng-template>
<div class="overlay" *ngIf="loading">
    <div class="spinnerss">
        <mat-spinner></mat-spinner>
    </div>
</div>
